<template>
  <div class="form-container">
    <div class="bs-flex">
      <h5>게시글 제목</h5>
      <input class="custom-input-box dis" disabled v-if="isEdit"/>
      <input class="custom-input-box" v-else/>
    </div>
    <div class="bs-flex">
      <h5>게시글 내용</h5>
      <textarea class="custom-textarea-box dis" cols="18" disabled v-if="isEdit"/>
      <textarea class="custom-textarea-box" cols="18" v-else/>
    </div>
    <hr>
    <div class="btn-flex" v-if="isEdit">
      <button class="delete-btn">
        삭제
      </button>
      <button class="edit-btn" @click="isEdit = false">
        수정
      </button>
    </div>
    <div class="btn-flex" v-else>
      <button class="complete-btn" @click="isEdit = true">
        완료
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsPrivateForm",
  data() {
    return {
      isEdit: true,
    }
  },
}
</script>

<style scoped>
.form-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px 18px;
}

.bs-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.bs-flex h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 0 10px 0 0;
  width: 150px;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, .1);
}

.dis{
  background: #F8F8FA !important;
}

.btn-flex {
  display: flex;
}

.custom-textarea-box {
  width: 100%;
  min-height: 180px;
  border-radius: 4px;
  border: 1px solid #E1E3E6 !important;
}

.delete-btn {
  width: 66px;
  height: 40px;
  background: #E14F4E;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
  margin-left: auto;
  margin-right: 10px;
}

.edit-btn {
  width: 66px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
}

.complete-btn {
  width: 66px;
  height: 40px;
  background: #000000;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
  margin-left: auto;
}


</style>
