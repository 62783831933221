<template>
  <div class="main-container">
    <h5 class="main-title">개인정보취급방침</h5>
    <div class="search-container">
      <div class="bs-flex">
        <h5 class="search-title">게시물 검색</h5>
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
        </div>
        <button class="search-btn">
          검색
        </button>
      </div>
    </div>
    <div class="sub-container">
      <button class="add-btn" @click="addBoard">
        게시글 등록
      </button>
    </div>
    <div class="table-container">
      <mdb-datatable-2
          v-model="data"
          class="text-center"
          borderless
          hover
          :searching="{value: search, field: fieldName} "
          noFoundMessage="data-null"/>
    </div>
    <div class="sub-container">
      <p>게시글</p>
    </div>
    <div class="result-null-container" v-if="isDataNull">
      <img src="@/assets/images/IA/img_null.png" alt="null">
      <h5>조회할 게시글을 선택해주세요.</h5>
    </div>
    <TermsPrivateForm v-else/>
  </div>
</template>

<script>
import {mdbDatatable2} from "mdbvue";
import TermsPrivateForm from "./TermsPrivateForm";

export default {
  name: "TermsPrivate",
  components: {
    TermsPrivateForm,
    mdbDatatable2,
  },
  data() {
    return {
      data: {
        rows: []
      },
      search: '',
      fieldName: '',
      isDataNull: null,
    }
  },
  methods: {
    addBoard() {
      this.$router.push('/master/terms/add')
    }
  }
}
</script>

<style scoped>
.main-container {
  background: white;
  padding: 44px 20px;
  min-width: 890px;
}

.main-title{
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.search-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.bs-flex {
  display: flex;
  align-items: center;
}

.search-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 8px 0 0;
  width: 100px;
}

.search-select {
  margin-right: 8px;
}

/deep/ .caret {
  display: none;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.search-input {
  padding-left: 30px;
  background: #FFFFFF;
  font-size: 16px;
}

.icon-input {
  width: 100%;
  position: relative;
  margin-right: 8px;
}

/deep/ .icon-input ::placeholder {
  font-size: 16px !important;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.sub-container {
  display: flex;
  flex-direction: row;
}

.sub-container p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-top: 26px;
  margin-bottom: 18px;

}

.search-btn {
  width: 108px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 0;
  margin-left: auto;
}

.add-btn {
  width: 140px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  border: 0;
  margin: 18px 0 10px auto;
}

.table-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}


.result-null-container {
  margin-top: 15px;
  width: 100%;
  height: 276px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-null-container img {
  width: 101px;
  margin-bottom: 16px;
}

.result-null-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  margin: 0;
}
</style>

